import { createApiRef } from "@backstage/core-plugin-api";
import { Entity } from "@backstage/catalog-model";
import { Feature } from "@cucumber/messages";

export const livingDocumentationApiRef = createApiRef<LivingDocumentationApi>({
    id: 'plugin.living-documentation.service',
});

export interface LivingDocumentationApi {
    getFeaturesByEntity(entity: Entity): Promise<Feature[]>;
}