import { DiscoveryApi, FetchApi } from "@backstage/core-plugin-api/index";
import { LivingDocumentationApi } from "./types";
import { Entity } from "@backstage/catalog-model";
import { Feature } from "@cucumber/messages";

export class LivingDocumentationBackendClient implements LivingDocumentationApi {
    private readonly discoveryApi: DiscoveryApi;
    private readonly fetchApi: FetchApi;
    
    constructor(options: {
        discoveryApi: DiscoveryApi;
        fetchApi: FetchApi;
    }) {
        this.discoveryApi = options.discoveryApi;
        this.fetchApi = options.fetchApi;
    }

    public async getFeaturesByEntity(entity: Entity): Promise<Feature[]> {
        const urlSegment = `${entity.metadata.namespace}/${entity.kind}/${entity.metadata.name}`;
        const url = `${await this.discoveryApi.getBaseUrl("living-documentation")}/v1/features/${urlSegment}`;
        const response = await this.fetchApi.fetch(url, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch features for entity: ${response.statusText}`);
        }

        return await response.json();
    }
}