import { Content, Header, Page } from '@backstage/core-components';
import {
  HomePageRandomJoke,
  HomePageStarredEntities,
  CustomHomepageGrid,
  WelcomeTitle,
  HomePageCompanyLogo,
  HomePageTopVisited,
  HomePageRecentlyVisited,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { AnnouncementsCard, NewAnnouncementBanner } from '@backstage-community/plugin-announcements';
import { Grid } from '@material-ui/core';
import React from 'react';
import RRLogo from './RRLogo';
import { PostsTableCard } from '@drodil/backstage-plugin-qeta';

const defaultConfig = [
  {
    component: <HomePageSearchBar />, // Or 'HomePageSearchBar' as a string if you know the component name
    x: 0,
    y: 0,
    width: 12,
    height: 2,
  },
  {
    component: <HomePageStarredEntities />,
    x: 0,
    y: 2,
    width: 6,
    height: 6,
  },
  {
    component: <AnnouncementsCard />,
    x: 6,
    y: 2,
    width: 6,
    height: 6,
  },
  {
    component: <HomePageRecentlyVisited />,
    x: 0,
    y: 3,
    width: 6,
    height: 6
  },
  {
    component: <PostsTableCard />,
    x: 6,
    y: 3,
    width: 6,
    height: 6
  },
  {
    component: <HomePageTopVisited />,
    x: 0,
    y: 4,
    width: 6,
    height: 6
  }
];

export const homePage = (
  <SearchContextProvider>
    <Header title={<WelcomeTitle />} pageTitleOverride="Home" />
    <Page themeId="home">
      <Content>
        <Grid container>
          <Grid item md={12}>
            <NewAnnouncementBanner />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={6}>
          <HomePageCompanyLogo logo={<RRLogo />} />
        </Grid>
        <CustomHomepageGrid config={defaultConfig}>
      // Insert the allowed widgets inside the grid
          <HomePageSearchBar />
          <HomePageRandomJoke />
          <HomePageStarredEntities />
          <AnnouncementsCard />
          <HomePageTopVisited />
          <HomePageRecentlyVisited />
          <PostsTableCard />
        </CustomHomepageGrid>
      </Content>
    </Page>
  </SearchContextProvider>
);