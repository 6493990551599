import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  createRouteRef,
  discoveryApiRef,
  fetchApiRef
} from '@backstage/core-plugin-api';
import { livingDocumentationApiRef, LivingDocumentationBackendClient } from './api';

export const rootCatalogLivingDocumentationRouteRef = createRouteRef({
  id: 'living-documentation',
});

export const livingDocumentationPlugin = createPlugin({
  id: 'living-documentation',
  apis: [
    createApiFactory({
      api: livingDocumentationApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new LivingDocumentationBackendClient({ discoveryApi, fetchApi }),
    })
  ],
  featureFlags: [{ name: 'enable-living-documentation' }],
  routes: {
    entityContent: rootCatalogLivingDocumentationRouteRef,
  },
});

export const EntityLivingDocumentationContent: (

) => JSX.Element = livingDocumentationPlugin.provide(
  createRoutableExtension({
    name: 'EntityLivingDocumentationContent',
    component: () => import('./Router').then(m => m.Router),
    mountPoint: rootCatalogLivingDocumentationRouteRef,
  }),
)