import React, { useEffect, useState } from 'react';
import { InfoCard, LinkButton } from '@backstage/core-components';
import { Grid, TextField } from '@material-ui/core';
import { alertApiRef, discoveryApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { Autocomplete } from '@material-ui/lab';

const getEntityTitle = (entity: Entity): string => {
    const displayName = (entity.spec?.profile as any)?.displayName;
    return displayName;
};

export const SendTestNotifications = () => {
    const [recipient, setRecipient] = React.useState<Entity | null>(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [availableEntities, setAvailableEntities] = React.useState<Entity[] | null>([]);

    const discoveryApi = useApi(discoveryApiRef);
    const catalogApi = useApi(catalogApiRef);
    const fetchApi = useApi(fetchApiRef);
    const alertApi = useApi(alertApiRef);

    useEffect(() => {
        catalogApi.getEntities({
            filter: {
                kind: 'User'
            },
            fields: [
                'kind',
                'metadata.name',
                'metadata.namespace',
                'metadata.title',
                'spec.profile.displayName'
            ]
        }).catch(_ => setAvailableEntities(null))
          .then(data => 
            data ? setAvailableEntities(data.items) : setAvailableEntities(null)
          );
    }, [
        catalogApi
    ]);


    const handleSend = async (event: React.MouseEvent) => {
        event.preventDefault();

        if (!recipient) {
            // A recipient must be selected in order to send the notification
            return;
        }

        const uri = await discoveryApi.getBaseUrl('test-notifications');
        const response = await fetchApi.fetch(`${uri}/send-test-notification`, {
            method: 'POST',
            body: JSON.stringify({
                recipient: stringifyEntityRef(recipient),
                title,
                description
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            const errorText = await response.text();
            alertApi.post({
                message: `Error sending notification: ${errorText}`,
                severity: 'error',
            })
            return;
        }
        
        alertApi.post({
            message: `Notification sent`,
            severity: 'success',
        });
    }

    if (!availableEntities || availableEntities.length === 0) {
        return null;
    }

    return (
        <InfoCard title="Test notifications" subheader="Send test notifications">
          <Grid container direction='column' spacing={3}>
            <Grid item xs={12}>
                <Autocomplete
                    value={recipient}
                    options={availableEntities}
                    disableListWrap
                    getOptionLabel={(option) => `${getEntityTitle(option)}`}
                    getOptionSelected={(option, value) => stringifyEntityRef(option) === stringifyEntityRef(value)}
                    onChange={(_e, newValue) => setRecipient(newValue)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            margin="normal"
                            label="Recipient" />
                    )} />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Title" variant="outlined" onChange={(e) => setTitle(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Description" variant="outlined" onChange={(e) => setDescription(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <LinkButton 
                to="."
                onClick={(e) => handleSend(e)}
                variant="contained"
                color="primary">
                  Send
              </LinkButton>
            </Grid>
          </Grid>
        </InfoCard>
    )
}